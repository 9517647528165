import Tabby from "tabbyjs";

const TABS = document.querySelector("[data-tabs]");
if (TABS) {
    new Tabby("[data-tabs]");

    const SELECT = document.querySelector(".tabbyjs__header--select");
    const LINKS  = document.querySelectorAll(".tabbyjs__button");

    if (SELECT) {
        SELECT.addEventListener("change", () => {
            LINKS.forEach((link) => {
                if (link.getAttribute("href") === SELECT.value) {
                    link.click();
                }
            });
        });
    }
}
