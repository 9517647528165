// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const FORM = document.querySelector(".search-form");

if (FORM) {
    const SEARCHSELECTORS = FORM.querySelectorAll(".search-form__select");
    const INPUT = FORM.querySelector("input[name=s]");

    SEARCHSELECTORS.forEach((searchSelector) => {
        searchSelector.addEventListener("change", () => {
            const setting = searchSelector.options[searchSelector.selectedIndex];
            FORM.setAttribute("target", setting.dataset.target);
            INPUT.setAttribute("placeholder", setting.dataset.placeholder);
        });
    });
}
