// JavaScript Document

// Scripts written by Jordan Bearce @ Weblinx, Inc.

import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from "swiper";

function load_swiper() {

    const HERO = document.querySelector(".swiper-container--hero");
    // init swiper
    if (HERO && HERO.querySelectorAll(".swiper-slide").length > 1) {
        new Swiper (HERO, {
            modules: [Autoplay, EffectFade, Navigation, Pagination],
            autoplay: {
                delay: 7500,
            },
            effect: "fade",
            loop: true,
            navigation: {
                nextEl: HERO.querySelector(".swiper-button--next"),
                prevEl: HERO.querySelector(".swiper-button--prev"),
            },
            pagination: {
                el: HERO.querySelector(".swiper-pagination"),
                clickable: true,
            },
            speed: 150,
        });
    }
}

document.addEventListener("DOMContentLoaded", load_swiper());
